import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import NumberFormatField from 'components/NumberFormatField';

export const WeightField = styled(NumberFormatField)(({ theme }) => ({
  '& fieldset': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  maxWidth: '90px',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '60px',
  },
}));

export const WeightUnit = styled(Typography)(({ theme }) => ({
  height: '40px',
  padding: theme.spacing(0, 2),
  backgroundColor: theme.palette.background.secondary,
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1),
  },
}));
