import { isNil } from 'lodash';

import { Bounty } from 'types/bounty.interface';
import { ProductInfo } from 'types/cart.interface';
import { Discount, Taxes } from 'types/order.interface';
import { formatReward, multiply } from 'utils/currencyUtils';
import { getTopLevelVariant } from 'utils/priceUtils';
import { add } from 'utils/rewardUtils';
import { getOnboardingInfo } from 'utils/storageUtils';

export const calculateTotalQuantity = (products: { [key: string]: ProductInfo }): number => {
  if (!products || !Object.keys(products)) {
    return 0;
  }

  return Object.values(products).reduce((acc: number, item: ProductInfo) => {
    acc += item?.weight ? 1 : Math.max(0, Math.ceil(item.quantity));
    return acc;
  }, 0);
};

export const formatProductForCart = (bounty: Bounty, quantity: number, weight?: string | null): ProductInfo => {
  const { product } = bounty;
  const { merchant } = product || {};
  const activeVariant = getTopLevelVariant(product);

  return {
    id: `temp:${bounty?.id}`,
    bountyId: bounty?.id,
    merchant,
    merchantId: merchant?.id,
    productSku: activeVariant?.sku || product?.merchantSku,
    name: product?.name,
    description: product?.description,
    imageUrl: product?.imageUrl,
    price: activeVariant?.price?.mainPrice,
    msrp: activeVariant?.msrp,
    addedAt: new Date().getTime(),
    availableQuantity: activeVariant?.quantity || 0,
    imageCdn: product?.imageCdn,
    manufacturer: activeVariant?.manufacturer || '',
    quantity: Math.max(0, quantity || 0),
    weight: weight || null,
    sortWeight: bounty?.product?.sortWeight,
    sortUnit: bounty?.product?.sortUnit,
  };
};

export function getCartTotal(products: ProductInfo[]) {
  let total: object | null = {};

  Object.values(products).forEach(({ price, quantity, weight }) => {
    const qty = isNil(weight) ? Math.max(0, quantity || 0) : 1;

    if (price) {
      total = total
        ? add(total, multiply(price, qty))
        : multiply(price, qty);
    }
  });

  return total;
}

export function calculateCartTotal(products: ProductInfo[]) {
  const total = getCartTotal(products);

  return formatReward(total, '0');
}

export function getDiscountsTotal(discounts: Discount[]) {
  let discountsTotal = 0;
  let discountCurrency = '';

  discounts?.forEach((discount: Discount) => {
    const discounAmount = discount?.amount?.money?.amount;
    discountsTotal += discounAmount ? +discounAmount : 0;
    discountCurrency = discount?.amount?.money?.currency;
  });

  return { discountsTotal, discountCurrency };
}

export function getTaxesTotal(taxes: Taxes[]) {
  let taxesTotal = 0;
  let taxesCurrency = '';

  taxes?.forEach((tax: Taxes) => {
    const amount = tax?.amount?.amount || 0;
    taxesTotal += +amount;
    taxesCurrency = tax?.amount?.currency;
  });

  return { taxesTotal, taxesCurrency };
}

export function formatSummaryProducts(products: ProductInfo[]) {
  return products?.reduce((acc: { [key: string]: ProductInfo }, item: ProductInfo) => ({
    [item.id]: item,
  }), {});
}

export function getStoreCartKey(): string {
  const { useType, storeId } = getOnboardingInfo() || {};

  if (storeId && useType) {
    return `${storeId}_${useType}`;
  }

  if (storeId) {
    return storeId;
  }

  return 'default';
}

export function generateUID() {
  return Date.now().toString(36);
}

export const getCartPriceValue = (
  { value, isLoading, hasFailed }: { value: string; isLoading?: boolean; hasFailed?: boolean},
) => {
  if (isLoading) {
    return 'Calculating...';
  }

  if (hasFailed) {
    return 'N/A';
  }

  return value;
};
