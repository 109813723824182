import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChangeEvent, FC } from 'react';

import { WeightField, WeightUnit } from './index.styled';

interface WeightSelectorProps {
  selectedWeight: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const WeightSelector:FC<WeightSelectorProps> = ({
  selectedWeight,
  onChange,
  onBlur,
  disabled = false,
}) => {
  const theme = useTheme();
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" alignItems="center">
      <WeightField
        label=""
        value={selectedWeight}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        size="small"
      />
      <WeightUnit>
        {matchesScreenSizeSm ? 'G' : 'grams'}
      </WeightUnit>
    </Box>
  );
};

export default WeightSelector;
