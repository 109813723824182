import { Box, Container, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  FC, useContext, useMemo, useState,
} from 'react';

import Brand from 'components/Brand';
import Dot from 'components/Dot';
import FreeTag from 'components/FreeTag';
import { StyledImage } from 'components/ProductCard/index.styled';
import ProductPrice from 'components/ProductPrice';
import ProductWeight from 'components/ProductWeight';
import CartConfirmation from 'containers/ProductDetailsPage/CartConfirmation';
import ProductDetailsSkeleton from 'containers/ProductDetailsPage/KioskProductDetails/index.skeleton';
import KioskQuantitySelector from 'containers/ProductDetailsPage/KioskQuantitySelector';
import { LocationContext } from 'context/LocationContext';

import useSummary from 'hooks/useSummary';

import { DASHBOARD_PRODUCT_COVER_SIZE } from 'constants/cover';
import { Bounty } from 'types/bounty.interface';
import { CartPriceDetails } from 'types/cart.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { formatCartDetails } from 'utils/checkoutUtils';
import { getPrices, isFree } from 'utils/priceUtils';
import { getManufacturer } from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface KioskProductDetailsProps {
  bounty: Bounty;
  isLoading: boolean;
}

const KioskProductDetails:FC<KioskProductDetailsProps> = ({ bounty, isLoading }) => {
  const { product } = bounty;
  const manufacturer = getManufacturer(product);
  const { onboardingInfo } = useContext(LocationContext);
  const { price, msrp } = getPrices(product, onboardingInfo?.store);
  const [summary, setSummary] = useState({
    total: '',
    showSummary: false,
  });
  const { checkSummary, isLoading: isCheckingSummary } = useSummary();
  const isProductFree = isFree(price);
  const coverUrl = useMemo(() => {
    if (!product) {
      return null;
    }

    const cover = getProductCover(
      product.imageUrl,
      DASHBOARD_PRODUCT_COVER_SIZE.w,
      DASHBOARD_PRODUCT_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover?.cdnUrl || cover?.url;
  }, [bounty.id]);

  const handleCartConfirmation = async () => {
    try {
      const data = await checkSummary();
      const formattedCardDetails = !isEmpty(data) ? formatCartDetails(data) : {} as CartPriceDetails;

      setSummary({
        total: formattedCardDetails.total ? formattedCardDetails.formattedTotal : '',
        showSummary: true,
      });
    } catch (e) {
      setSummary({
        total: '',
        showSummary: true,
      });
    }
  };

  if (summary.showSummary) {
    return <CartConfirmation cartTotal={summary.total} />;
  }

  return (
    <Container maxWidth="sm">
      {isLoading
        ? <ProductDetailsSkeleton />
        : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box margin="0 auto" height={150}>
              <StyledImage
                component="img"
                src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
                alt={product?.name}
              />
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="h5" fontWeight="500">
                {product?.name}
              </Typography>
              {manufacturer && (
                <Brand brand={manufacturer} variant="body3" />
              )}
              <Box display="flex" alignItems="center" justifyContent="center" gap={1} my={1}>
                {isProductFree
                  ? <FreeTag fontWeight={700} variant="body1" />
                  : <ProductPrice price={price} msrp={msrp} fontWeight={500} variant="h5" />}
                {product.sortWeight && (
                  <>
                    <Dot />
                    <ProductWeight fontWeight={500} variant="h5" weight={product.sortWeight} />
                  </>
                )}
              </Box>
            </Box>

            <KioskQuantitySelector
              isLoading={isCheckingSummary}
              bounty={bounty}
              setShowConfirmation={handleCartConfirmation}
            />
            <Typography my={4}>
              {product?.description}
            </Typography>
          </Box>
        )}
    </Container>
  );
};

export default KioskProductDetails;
