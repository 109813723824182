import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

interface PriceProps {
  price?: string;
  fontWeight: number;
  variant: Variant;
  weight?: number | null;
}

const Price :FC<PriceProps> = ({
  price = '--', fontWeight, variant, weight = null,
}) => (
  <Typography variant={variant} fontWeight={fontWeight}>
    {weight ? `${price} / ${weight} (G)` : price}
  </Typography>
);

export default Price;
