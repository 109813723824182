import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FC, useMemo } from 'react';

import { FreeText, StyledImage } from 'components/PreviewCartProduct/index.styled';

import { CART_PRODUCT_PREVIEW_COVER_SIZE } from 'constants/cover';
import { WeightUnit } from 'constants/enums';
import { ProductInfo } from 'types/cart.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { formatReward } from 'utils/currencyUtils';
import { textTruncate } from 'utils/formatters';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface PreviewCartProductProps {
  product: ProductInfo;
  descriptionLength?: number;
}

const PreviewCartProduct :FC<PreviewCartProductProps> = ({ product, descriptionLength = 150 }) => {
  const { price, quantity } = product;
  const formattedPrice = formatReward(price, '0');
  const coverUrl = useMemo(() => {
    const cover = getProductCover(
      product.imageUrl,
      CART_PRODUCT_PREVIEW_COVER_SIZE.w,
      CART_PRODUCT_PREVIEW_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [product.id]);

  return (
    <Box mb={2}>
      <Box display="flex" mb={1}>
        <Box width="100%" flexBasis="60px" textAlign="center">
          <StyledImage
            src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
            alt={product?.name}
          />
        </Box>
        <Box mx={1} overflow="hidden">
          <Typography variant="body2" fontWeight={700} noWrap>
            {product?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {textTruncate(product?.description, descriptionLength)}
          </Typography>
        </Box>
      </Box>

      {isNil(product?.weight)
        ? (
          <Typography variant="body2" flexGrow={1} textAlign="right">
            {formattedPrice || <FreeText>Free</FreeText>}
            {' X '}
            {quantity}
          </Typography>
        ) : (
          <Typography variant="body2" flexGrow={1} textAlign="right">
            {`${product?.weight} (${product?.sortUnit || WeightUnit.G})`}
          </Typography>
        )}
    </Box>
  );
};

export default PreviewCartProduct;
