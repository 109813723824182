import {
  Container, Grid, Box,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  Suspense, lazy, FC, useContext,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import EmptyState from 'components/EmptyState';
import ProductCardSkeleton from 'components/ProductCard/index.skeleton';
import UnstyledLink from 'components/UnstyledLink';
import Categories from 'containers/Categories';
import HighlightsBanner from 'containers/HighlightsBanner';
import { ActionsWrapper } from 'containers/KioskProducts/index.styled';
import ProductFilterFlyout from 'containers/ProductFilterFlyout';
import ProductSortFlyout from 'containers/ProductSortFlyout';
import PromotionBanner from 'containers/PromotionBanner';
import RecommendationsBanner from 'containers/RecommendationsBanner';
import { CategoryContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { PRODUCT_DETAILS_ROUTE } from 'constants/clientRoutes';
import { BannerType, ProductSortOptions, ViewMode } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Promotion } from 'types/promotion.interface';
import { formatRoute } from 'utils/formatters';

const ProductCard = lazy(() => import('components/ProductCard'));

interface KioskProductsProps {
  promotions?: Record<BannerType, Promotion[]>;
  onLoadProducts: () => void;
  skeletonProducts: number[];
  products: Bounty[] | null;
  hasMore: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  onSort: (newSort: ProductSortOptions) => void;
  sortOption: ProductSortOptions;
}

const KioskProducts: FC<KioskProductsProps> = ({
  promotions,
  onLoadProducts,
  skeletonProducts,
  products,
  hasMore,
  isLoadingMore,
  isLoading,
  onSort,
  sortOption,
}) => {
  const { categories } = useContext(CategoryContext);
  const { shopSettings } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);
  const hasCategories = categories?.length > 0;
  const isDescriptionVisible = shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full;
  const {
    [BannerType.Hero]: heroes = [],
    [BannerType.Recommendations]: recommendations = [],
    [BannerType.Highlights]: highlights = [],
  } = promotions || {};
  const isListVisible = !(highlights?.length > 0 && recommendations?.length > 0);

  return (
    <Box position="relative">
      <Container>
        <Grid container spacing={2}>
          {hasCategories && (
            <Grid item xs={3} mt={heroes?.length > 0 ? 1 : 0}>
              <Categories />
            </Grid>
          )}

          <Grid item xs={hasCategories ? 9 : 12}>
            {heroes?.length > 0 && (
              <PromotionBanner
                sx={{ px: heroes?.length > 1 ? 6 : 0, mt: 0 }}
                promotions={heroes}
              />
            )}
            {highlights?.length > 0 && <HighlightsBanner highlights={highlights} sx={{ mb: 6 }} /> }
            {recommendations?.length > 0 && (
              <RecommendationsBanner
                recommendations={recommendations}
                sx={{ mb: 6 }}
              />
            )}

            {isListVisible && (
              <div>
                {!isLoading && isNil(products)
                  ? <EmptyState message="No products found!" />
                  : (
                    <>
                      <ActionsWrapper>
                        <ProductSortFlyout sortOption={sortOption} onChange={onSort} />
                        <ProductFilterFlyout />
                      </ActionsWrapper>

                      <InfiniteScroll
                        pageStart={0}
                        loadMore={onLoadProducts}
                        hasMore={hasMore}
                      >
                        <Grid container spacing={2}>
                          {!isLoading && products && products.map((bounty, index) => (
                            <Grid key={`${index}-${bounty.id}`} item xs={12} sm={6} md={6} lg={4}>
                              <Suspense fallback={<ProductCardSkeleton />}>
                                <UnstyledLink
                                  to={formatRoute(PRODUCT_DETAILS_ROUTE, { productId: bounty.id })}
                                  height="100%"
                                  width="100%"
                                >
                                  <ProductCard
                                    bounty={bounty}
                                    isDescriptionVisible={isDescriptionVisible}
                                    store={onboardingInfo?.store}
                                  />
                                </UnstyledLink>
                              </Suspense>
                            </Grid>
                          ))}

                          {(isLoading || isLoadingMore) && skeletonProducts.map((loader) => (
                            <Grid key={`productSkeleton-${loader}`} item xs={12} sm={6} md={6} lg={4}>
                              <ProductCardSkeleton />
                            </Grid>
                          ))}
                        </Grid>
                      </InfiniteScroll>
                    </>
                  )}
              </div>
            )}

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default KioskProducts;
