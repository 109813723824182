import { isNil } from 'lodash';

import {
  ChemicalKind, ChemicalViewMode, ViewMode, WeightUnit,
} from 'constants/enums';
import { CHEMICALS_FOR_SIMPLIFY_MODE } from 'constants/general';
import { Bounty } from 'types/bounty.interface';
import { Cart } from 'types/cart.interface';
import { ChemicalInfo, Product, Tag } from 'types/product.interface';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getShopSettingsFromStorage } from 'utils/storageUtils';

export const generateEmptyArray = (length: number, allowZero = false) => {
  const arrayLength = allowZero ? length + 1 : length;
  return Array.from({ length: arrayLength }, (_, i) => (allowZero ? i : i + 1));
};

export const getRealQuantity = (quantity: number|undefined, bountyId: string, cart: Cart): number => {
  const productQuantity = Math.max(0, quantity || 0);
  const quantityFromCart = Math.max(0, cart?.products?.[`temp:${bountyId}`]?.quantity || 0);

  return Math.max(0, productQuantity - quantityFromCart);
};

export const getManufacturer = (product: Product): string => {
  const variant = getTopLevelVariant(product);
  return variant?.manufacturer || '';
};

export const getChemicalElementPercent = (element: ChemicalInfo) => (
  element.maxValue && element.uom !== '%' ? Math.round((100 * element.value) / element.maxValue) : undefined
);

export const formatChemicalValue = ({ element, summary }: { element: ChemicalInfo; summary: boolean}) => {
  if (!element.value) return '';
  const value = summary && element.uom === '%' ? Math.round(element.value) : element.value;
  return `${value}${element.uom}`;
};

export const formatProductWeight = (weight: number) => weight.toFixed(2);

export const filterChemicals = (chemicals: ChemicalInfo[]) => {
  const shopSettings = getShopSettingsFromStorage() || {};
  const viewMode = shopSettings?.viewConfigs?.PRODUCT?.viewMode || ViewMode.Full;

  if (viewMode === ViewMode.Full) {
    return chemicals;
  }

  return chemicals?.filter(({ name }) => (CHEMICALS_FOR_SIMPLIFY_MODE.includes(name)));
};

export const getProductStrainType = (tags: Record<string, Tag>): Tag | null => {
  const typeKey = Object.keys(tags).find((key) => key.startsWith('strain_type_'));
  return typeKey ? tags[typeKey] : null;
};

export const getSortedChemicals = (chemicals: ChemicalInfo[]): ChemicalInfo[] => {
  const kindPriority = {
    [ChemicalKind.Cannabinoid]: 1,
    [ChemicalKind.Terpene]: 2,
    [ChemicalKind.Other]: 3,
    '': 4,
  };

  return chemicals.sort((a, b) => {
    const priorityA = kindPriority[a.kind] || kindPriority[''];
    const priorityB = kindPriority[b.kind] || kindPriority[''];
    return priorityA - priorityB;
  });
};

export const getPreviewChemicals = (searchParams: URLSearchParams, keys: string[]) => keys?.map((key) => {
  const viewMode = searchParams?.get(`${key}-viewMode`) as ChemicalViewMode;

  return {
    name: searchParams?.get(`${key}-name`) || '',
    kind: searchParams?.get(`${key}-kind`) as ChemicalKind,
    summary: [ChemicalViewMode.TileDetails, ChemicalViewMode.Tile].includes(viewMode),
    secondary: [ChemicalViewMode.TileDetails, ChemicalViewMode.Details].includes(viewMode),
    value: 1,
    uom: 'mg',
  } as ChemicalInfo;
});

export const getProductEquivalentQuantity = (weight: string, productWeight: number) => (
  +(+weight / productWeight).toFixed(1)
);

export const shouldUseProductWeight = (bounty: Bounty) => (
  bounty?.product?.sortUnit === WeightUnit.G && !isNil(bounty?.product?.sortWeight)
);
