import { format } from 'date-fns';

import {
  AddressAutocompleteMode,
  DeliveryMethod, FieldRuleType, PaymentMethod, ProductUseType, TribalIdMode,
} from 'constants/enums';
import { DEFAULT_TIME_FORMAT, ORDER_DATE_FORMAT, US_COUNTRY_CODE } from 'constants/general';
import { BalanceCatInfo, CartPriceDetails } from 'types/cart.interface';
import { PlaceOrderInfo } from 'types/checkout.interface';
import { Reward } from 'types/money.interface';
import { OnboardingInfo } from 'types/onboarding.interface';
import { PriceSummary } from 'types/order.interface';
import { ShopHoursOfOperation } from 'types/shopOperation.interface';
import { getDiscountsTotal, getTaxesTotal } from 'utils/cartUtils';
import { formatReward } from 'utils/currencyUtils';
import { getShopSettingsFromStorage } from 'utils/storageUtils';
import { getShopConfiguration } from 'utils/storeUtils';

export const formatCheckoutValues = (
  values: PlaceOrderInfo,
  onboardingInfo: OnboardingInfo|null,
  fieldsRules?: Record<string, FieldRuleType>,
  kioskMode = false,
) => {
  const {
    customer, delivery, medical, payment, address, driverLicense,
  } = values || {};
  const {
    pickupDate, pickupTime, method, note,
  } = delivery || {};
  const { medicalId, medicalIdExp } = medical || {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { tempState, ...addressProps } = address || {};
  const formattedDelivery = kioskMode
    ? { method: DeliveryMethod.KIOSK }
    : {
      method,
      pickupDate: pickupDate ? format(new Date(pickupDate || ''), ORDER_DATE_FORMAT) : null,
      ...(pickupTime ? { pickupTime: `${format(new Date(pickupTime || ''), DEFAULT_TIME_FORMAT)}` } : {}),
      ...(method === DeliveryMethod.DELIVERY
        ? {
          note,
          address: {
            ...addressProps,
            countryCode: addressProps?.countryCode || US_COUNTRY_CODE,
          },
        } : {}),
      ...(method === DeliveryMethod.CURBSIDE && addressProps?.phoneNumber
        ? {
          address: {
            phoneNumber: addressProps?.phoneNumber,
            countryCode: addressProps?.countryCode || US_COUNTRY_CODE,
          },
        } : {}),
    };

  return {
    customer: {
      ...customer,
      dob: customer?.dob ? format(new Date(customer?.dob), ORDER_DATE_FORMAT) : null,
    },
    payment,
    delivery: formattedDelivery,
    ...(onboardingInfo?.useType === 'is_medical_use'
      ? {
        medical: {
          medicalId: medicalId || null,
          medicalIdExp: medicalIdExp ? format(new Date(medicalIdExp), ORDER_DATE_FORMAT) : null,
        },
      } : {}),
    useType: onboardingInfo?.useType
      ? ProductUseType[onboardingInfo.useType as keyof typeof ProductUseType]
      : null,
    driverLicense: {
      ...(fieldsRules?.driverLicenseState !== FieldRuleType.Hidden
        ? { state: driverLicense?.state, countryCode: driverLicense?.countryCode }
        : {}
      ),
      ...(fieldsRules?.driverLicenseNumber !== FieldRuleType.Hidden ? { number: driverLicense?.number } : {}),
      ...(fieldsRules?.driverLicenseExpirationDate !== FieldRuleType.Hidden
        ? { expDate: driverLicense.expDate ? format(new Date(driverLicense.expDate || ''), ORDER_DATE_FORMAT) : null }
        : {}
      ),
    },
  };
};

export const getCardDetails = (priceSummary?: PriceSummary) => {
  const {
    total, itemsTotal, balances, taxes, subTotal, discounts, roundingAmount,
  } = priceSummary || {};

  const { taxesTotal = 0, taxesCurrency = '' } = taxes ? getTaxesTotal(taxes) : {};
  const { discountsTotal = 0, discountCurrency = '' } = discounts ? getDiscountsTotal(discounts) : {};

  return formatCartDetails({
    subTotal,
    roundingAmount,
    total,
    taxesTotal,
    taxesCurrency,
    discountsTotal,
    discountCurrency,
    balances,
    itemsTotal,
  });
};

export const formatCartDetails = ({
  balances,
  total,
  subTotal,
  taxesTotal,
  roundingAmount,
  itemsTotal,
  taxesCurrency,
  discountCurrency,
  discountsTotal,
}: {
  balances?: { [key: string]: BalanceCatInfo };
  total?: Reward;
  subTotal?: Reward;
  itemsTotal?: Reward;
  roundingAmount?: Reward;
  taxesTotal: number;
  taxesCurrency: string;
  discountCurrency: string;
  discountsTotal: number;
}) : CartPriceDetails => ({
  total: total?.money?.amount,
  subTotal: formatReward(subTotal, '0', true),
  itemsTotal: formatReward(itemsTotal, '0', true),
  formattedTotal: formatReward(total, '0', true),
  taxes: formatReward(
    { money: { amount: taxesTotal.toString(), currency: taxesCurrency } },
    '0',
    true,
  ),
  roundingAmount: roundingAmount?.money?.amount?.includes('0.00')
    ? ''
    : formatReward(roundingAmount, '', true),
  discounts: discountsTotal > 0
    ? `${discountsTotal > 0 && '-'}${formatReward(
      { money: { amount: discountsTotal.toString(), currency: discountCurrency } },
      '0',
      true,
    )}`
    : undefined,
  balances,
});

export const showCheckoutForms = (checkoutFields?: Record<string, FieldRuleType>) => {
  const showDriverLicenseForm = checkoutFields?.driverLicenseState !== FieldRuleType.Hidden
    || checkoutFields?.driverLicenseNumber !== FieldRuleType.Hidden
    || checkoutFields?.driverLicenseExpirationDate !== FieldRuleType.Hidden;
  const showUserInfoForm = checkoutFields?.firstName !== FieldRuleType.Hidden
  || checkoutFields?.lastName !== FieldRuleType.Hidden
  || checkoutFields?.dob !== FieldRuleType.Hidden
  || checkoutFields?.state !== FieldRuleType.Hidden
  || checkoutFields?.email !== FieldRuleType.Hidden
  || checkoutFields?.phoneNumber !== FieldRuleType.Hidden;

  return {
    showDriverLicenseForm,
    showUserInfoForm,
  };
};

export const isIntegratedPayment = (paymentMethod: PaymentMethod) => (
  [PaymentMethod.BILLMYBANK, PaymentMethod.CHARGEE].includes(paymentMethod)
);

export const isPickupMethod = (method: DeliveryMethod) => (
  [DeliveryMethod.PICKUP, DeliveryMethod.CURBSIDE].includes(method)
);

export const hasOrderDateRestrictions = (method: DeliveryMethod) => (
  [DeliveryMethod.PICKUP, DeliveryMethod.CURBSIDE, DeliveryMethod.DELIVERY].includes(method)
);

export const getDayLimitBasedOnDelivery = (hoursOfOperation: ShopHoursOfOperation, deliveryMethod: DeliveryMethod) => {
  if (isPickupMethod(deliveryMethod)) {
    return hoursOfOperation.pickup?.canSubmitOrdersInDays || 0;
  }

  if (deliveryMethod === DeliveryMethod.DELIVERY) {
    return hoursOfOperation.delivery?.canSubmitOrdersInDays || 0;
  }

  return 0;
};

export const isEmbedded = () => {
  const { embed } = getShopConfiguration();
  return embed;
};

export const isAddressAutocomplete = () => {
  const { addressAutocomplete } = getShopConfiguration();
  if (addressAutocomplete === true || addressAutocomplete === false) return addressAutocomplete;
  const shopSettings = getShopSettingsFromStorage() || {};
  switch (shopSettings?.addressAutocomplete) {
    case AddressAutocompleteMode.None: return false;
    case AddressAutocompleteMode.Google: return true;
    default: return !isEmbedded();
  }
};

export const isTribalIdAllowed = () => {
  const shopSettings = getShopSettingsFromStorage() || {};
  switch (shopSettings?.tribalIdMode) {
    case TribalIdMode.None: return false;
    case TribalIdMode.AsDriverLicense: return true;
    default: return false;
  }
};
