import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Typography, Box, IconButton, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isNil } from 'lodash';
import {
  ChangeEvent,
  FC, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';

import Brand from 'components/Brand';
import FreeTag from 'components/FreeTag';
import Price from 'components/Price';
import QuantityButtonsSelector from 'components/QuantityButtonsSelector';
import QuantitySelector from 'components/QuantitySelector';
import RemoveProductConfirmation from 'components/RemoveProductConfirmation';
import SummaryStockInfo from 'components/SummaryStockInfo';
import UnstyledLink from 'components/UnstyledLink';
import WeightSelector from 'components/WeightSelector';
import {
  StyledBox,
  StyledDescription,
  StyledImage,
  StyledPrice,
} from 'containers/CartProduct/index.styled';
import { CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getProductDetailsRoute } from 'hooks/useRouting';

import { CART_PRODUCT_COVER_SIZE } from 'constants/cover';
import { ProductInfo } from 'types/cart.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { calculateCartTotal } from 'utils/cartUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';
import { reportAlert } from 'utils/utils';

interface CartProductProps {
  product: ProductInfo;
  isProductUpdated?: boolean;
  wasAdjusted?: boolean;
  updatedQuantity?: number;
  handleChange?: (productId: string) => void;
  isDisabled?: boolean;
}

const CartProduct:FC<CartProductProps> = (props) => {
  const {
    product, updatedQuantity = 0, isProductUpdated, handleChange,
    wasAdjusted, isDisabled = false,
  } = props;
  const { kioskMode } = useKiosk();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [currentWeight, setCurrentWeight] = useState<string>(product?.weight || '');
  const { setQuantity, removeItem, setWeight } = useContext(CartDispatchContext);
  const { handleTrackRemoveFromCart } = useGoogleAnalytics();
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));

  const totalPrice = calculateCartTotal([product]);
  const isOutOfStock = Boolean(isProductUpdated && updatedQuantity <= 0);
  const selectedQuantity = isOutOfStock ? 0 : product.quantity;
  const updatedQuantityLabel = isOutOfStock
    ? 'Sorry, the item is no longer available due to stock.'
    : `The quantity has changed, there are only ${updatedQuantity} left.`;

  const { availableQuantity = 0, manufacturer, balances = {} } = product;
  const coverUrl = useMemo(() => {
    const cover = getProductCover(
      product.imageUrl,
      CART_PRODUCT_COVER_SIZE.w,
      CART_PRODUCT_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [product.id]);

  const handleClose = () => setIsOpen(false);

  const handleRemove = () => {
    removeItem(product.id);
    handleTrackRemoveFromCart(product);
    toast.success('Product was removed from cart!');
  };

  const handleSubmit = () => {
    handleRemove();
    handleClose();
  };

  const handleQuantityChanges = (newQuantity: number) => {
    if (newQuantity <= 0) {
      setIsOpen(true);
    } else {
      setQuantity(product.id, newQuantity);
    }

    if (handleChange) {
      handleChange(product.id);
    }
  };

  const handleDecreaseQuantity = () => {
    if (selectedQuantity - 1 <= 0) {
      setIsOpen(true);
      return;
    }

    setQuantity(product.id, selectedQuantity > 0 ? selectedQuantity - 1 : 0);
  };

  const handleIncreaseQuantity = () => {
    if (availableQuantity === selectedQuantity) {
      toast.warning('You\'ve reached the maximum available amount for this product!');
      return;
    }

    setQuantity(product.id, selectedQuantity + 1);
  };

  const checkIfOptionIsDisabled = (quantity: number) => {
    if (!isProductUpdated) {
      return false;
    }

    return Math.max(0, quantity) > Math.max(0, updatedQuantity);
  };

  const handleWeightChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCurrentWeight(value);
  };

  const handleSaveWeight = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (!product?.sortWeight) {
      reportAlert(`Cannot change product weight, missing sortWeight:\n${JSON.stringify(product)}`);
      toast.info('The product is missing important information, we can\'t change the weight.');
      return;
    }

    if (!value || +value === 0) {
      handleRemove();
      return;
    }

    setWeight(product?.id, value, product?.sortWeight);
  };

  const renderQuantitySelector = () => (kioskMode ? (
    <Box minWidth="200px">
      <QuantityButtonsSelector
        onDecrease={handleDecreaseQuantity}
        onIncrease={handleIncreaseQuantity}
        quantity={selectedQuantity}
      />
    </Box>
  ) : (
    <Box display="flex" alignItems="center" gap={matchesScreenSizeSm ? 1 : 2}>
      {!isNil(product?.weight)
        ? (
          <>
            {!matchesScreenSizeSm && (
              <Typography variant="body1">
                Weight
              </Typography>
            )}
            <WeightSelector
              onChange={handleWeightChange}
              onBlur={handleSaveWeight}
              selectedWeight={currentWeight}
              disabled={isOutOfStock || isDisabled}
            />
          </>
        )
        : (
          <>
            {!matchesScreenSizeSm && (
              <Typography variant="body1">
                Quantity
              </Typography>
            )}
            <QuantitySelector
              allowZero
              disabled={isOutOfStock || isDisabled}
              selectedQuantity={selectedQuantity}
              handleQuantity={handleQuantityChanges}
              availableQuantity={availableQuantity}
              checkIfOptionIsDisabled={checkIfOptionIsDisabled}
            />
          </>
        )}

      <IconButton
        disabled={isOutOfStock || isDisabled}
        sx={{ p: 0.5 }}
        onClick={() => handleQuantityChanges(0)}
        id="iconButton-removeItem"
      >
        <CancelOutlinedIcon sx={{ color: 'general.lightGrey1' }} />
      </IconButton>
    </Box>
  ));

  return (
    <StyledBox hasBottomBorder={!(balances && Object.keys(balances).length > 0)}>
      <Box sx={{ opacity: isOutOfStock ? 0.5 : 1 }}>
        <Box display="flex">
          <StyledImage
            src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
            alt={product?.name}
          />
          <Box pb={1} maxWidth="500px" width="100%">
            {isOutOfStock || isDisabled
              ? (
                <Typography variant="body1" fontWeight={600}>
                  {product?.name}
                </Typography>
              ) : (
                <UnstyledLink to={getProductDetailsRoute(product.bountyId)}>
                  <Typography variant="body1" fontWeight={600}>
                    {product?.name}
                  </Typography>
                </UnstyledLink>
              )}
            {!!manufacturer && (
              <Brand brand={manufacturer} sx={{ mb: 1 }} disabled={isOutOfStock || isDisabled} />
            )}
            <StyledDescription variant="body1" color="textSecondary">
              {product?.description}
            </StyledDescription>
          </Box>
        </Box>

        <StyledPrice>
          <Box display="flex" alignItems="center">
            {availableQuantity > 0
              ? renderQuantitySelector()
              : (
                <Typography variant="body2" color="error">
                  Out of stock
                </Typography>
              )}
          </Box>
          {totalPrice
            ? (
              <Price
                fontWeight={700}
                variant="body1"
                price={totalPrice}
                weight={!isNil(product?.weight) ? product?.sortWeight : null}
              />
            ) : <FreeTag />}
        </StyledPrice>
      </Box>

      {(isProductUpdated && !wasAdjusted) && (
        <SummaryStockInfo
          updatedQuantityLabel={updatedQuantityLabel}
          onRemove={handleRemove}
          isOutOfStock={isOutOfStock}
        />
      )}

      {isOpen && (
        <RemoveProductConfirmation
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </StyledBox>
  );
};

export default CartProduct;
